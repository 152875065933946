<template>
  <ev-modal @close="handleClose">
    <applicant-card-infos v-if="candidate" :candidate="candidate" />

    <section class="applicant-modal" />

    <router-view />
  </ev-modal>
</template>

<script>
import EvModal from '@revelotech/everestV2/EvModal'
import ApplicantCardInfos from '@/pages/Applicant/components/ApplicantCardInfos'

import { APPLICANTS } from '@/store/namespaces'
import { createNamespacedHelpers } from 'vuex'

const applicantsHelper = createNamespacedHelpers(APPLICANTS)

export default {
  name: 'ApplicantModal',

  components: { EvModal, ApplicantCardInfos },

  computed: {
    ...applicantsHelper.mapState(['applicant']),

    candidate () {
      return this.applicant?.candidate
    }
  },

  mounted () {
    this.getApplicant(this.$route.params.applicantId)
  },

  methods: {
    ...applicantsHelper.mapActions(['getApplicant']),

    handleClose () {
      this.$router.push({ name: 'NewHome' })
    }
  }
}

</script>

<style scoped lang="scss">

.applicant-modal {
  @include margin('top', 6);

  border-top: solid 1px $gray40;
  padding-top: 6*$base;
}

</style>
