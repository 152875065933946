<template>
  <div class="source-badge">
    <ev-badge variant="gray" :title="badgeText" data-test="badge" />
  </div>
</template>

<script>
import EvBadge from '@revelotech/everestV2/EvBadge'
import { toCamelCase } from '@/helpers/case-style'

const EXTERNAL_SOURCE_INFOS = ['LinkedIn', 'GitHub', 'Indicação']
const SOURCES = ['prime', 'marketplace', 'application_page', 'shortlist']

export default {
  name: 'SourceBadge',
  components: {
    EvBadge
  },
  props: {
    source: {
      type: String,
      required: true
    },
    sourceInfo: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      langPath: __langpath
    }
  },
  computed: {
    isFromExternalSource () {
      return this.source === 'external'
    },
    isFromKnownSource () {
      return SOURCES.includes(this.source)
    },
    wasAddedOnKnownExternalSource () {
      return EXTERNAL_SOURCE_INFOS.includes(this.sourceInfo)
    },
    isReferral () {
      return this.isFromExternalSource && this.sourceInfo === 'Indicação'
    },
    caption () {
      if (this.isFromKnownSource) return toCamelCase(this.source)
      if (this.isReferral) return 'referral'
      if (this.wasAddedOnKnownExternalSource) return this.sourceInfo.toLowerCase()

      return 'manuallyAdded'
    },
    badgeText () {
      return this.$t(`${this.langPath}.${this.caption}`)
    }
  }
}
</script>
