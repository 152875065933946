<template>
  <section class="applicant-card-infos">
    <figure
      v-if="candidate.avatar"
      class="applicant-card-infos__picture"
    >
      <img
        class="applicant-card-infos__picture-img"
        data-test="applicant__avatar"
        :alt="candidate.name"
        :src="candidate.avatar"
      >
    </figure>
    <main class="applicant-card-infos__content">
      <section
        :class="{
          'applicant-card-infos__title': true,
          'applicant-card-infos__title--picture-aside': candidate.avatar
        }"
      >
        <h3 class="applicant-card-infos__name">{{ candidate.name }}</h3>
        <router-link
          v-if="externalCandidate"
          class="applicant-card-infos__edit-link"
          :to="{ name: 'ApplicantEdit', params: $route.params }"
        >
          <icon-base
            icon-color="#72869c"
            icon-name="account-edit"
          />
        </router-link>
        <span
          class="applicant-card-infos__source-tag"
          data-test="candidate_source_info"
        >
          <source-badge
            v-if="candidate.source"
            class="workflow-card__source-badge"
            data-test="source-badge"
            :source="candidate.source"
            :source-info="candidate.sourceInfo"
          />
        </span>
      </section>
      <p class="applicant-card-infos__resume">
        {{ candidate.jobTitle }}
      </p>
      <p
        v-if="showSourceInfo"
        data-test="source-info"
        class="applicant-card-infos__resume"
      >
        {{ $t(`${langPath}.note`) }} {{ candidate.sourceInfo }}
      </p>
      <p class="applicant-card-infos__resume">
        {{ candidate.city }}
      </p>
      <p v-if="candidate.experienceTime" class="applicant-card-infos__resume">
        {{ candidate.experienceTime | experienceTime }}
        {{ $t(`${langPath}.experience`) }}
      </p>
      <p class="applicant-card-infos__resume">
        <span
          v-if="canSeeSalary"
          data-test="can-see-salary"
        >
          {{ salary }}
        </span>
      </p>
      <router-link
        v-if="marketplaceCandidate"
        class="applicant-card-infos__link"
        data-ga-id="clicked_candidate_full_profile"
        data-test="full-profile-link"
        target="_blank"
        :to="candidateProfileRoute"
      >
        {{ $t(`${langPath}.seeProfile`) }}
      </router-link>
    </main>
  </section>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { CURRENT_USER } from '@/store/namespaces'
import IconBase from '@/components/IconBase'
import SourceBadge from '@/components/Candidates/SourceBadge'

const currentUserHelper = createNamespacedHelpers(CURRENT_USER)

export default {
  name: 'ApplicantCardInfos',
  components: { IconBase, SourceBadge },
  props: {
    candidate: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      langPath: __langpath
    }
  },
  computed: {
    ...currentUserHelper.mapState(['currentUser']),
    canSeeSalary () {
      return !(this.currentUser?.canSeeSalary === false)
    },
    candidateProfileRoute () {
      return {
        name: 'CandidateProfile',
        params: { id: this.candidate.externalId }
      }
    },
    externalCandidate () {
      return this.candidate?.source === 'external'
    },
    showSourceInfo () {
      const sourceInfos = ['LinkedIn', 'GitHub', 'Indicação', 'Outros']

      return this.externalCandidate && !sourceInfos.includes(this.candidate.sourceInfo)
    },
    marketplaceCandidate () {
      return this.candidate.source === 'marketplace'
    },
    salary () {
      return ['currentSalary', 'desiredSalary']
        .filter((field) => this.candidate[field])
        .map((field) => {
          return this.$t(`${this.langPath}.${field}`) + ' ' +
            this.$options.filters.brMoneyMask(this.candidate[field])
        }).join(' - ')
    }
  }
}
</script>

<style lang="scss">
.applicant-card-infos {
  display: flex;
  justify-content: space-between;
  width: 100%;

  &__picture {
    align-items: center;
    border-radius: 50%;
    display: flex;
    flex: 0 0 auto;
    height: 80px;
    justify-content: center;
    margin-right: $base*6;
    overflow: hidden;
    width: 80px;

    &-img {
      max-width: 100%;
      min-height: 100%;
      width: 100%;
    }
  }

  &__picture-initials {
    @extend %h5;

    background-color: $blue-light;
    color: $white;
    height: 100%;
    line-height: 80px;
    text-align: center;
    width: 100%;
  }

  &__content {
    flex-grow: 1;
  }

  &__name {
    @extend %h4;
  }

  &__title {
    align-items: center;
    display: flex;
    margin-bottom: 8*$base;

    &--picture-aside {
      margin-bottom: 3*$base;
    }
  }

  &__resume {
    @extend %body-text2;
  }

  &__edit-link {
    @include margin('left', 3);

    align-self: center;
  }

  &__link {
    @extend %body-text2;

    color: $blue;
    text-decoration: underline;
  }

  &__source-info {
    @include margin('top', 2);
  }

  &__source-tag {
    @include margin('left', 3);
  }
}
</style>
